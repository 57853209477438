import copy from "copy-to-clipboard";
import React, { useEffect, useState } from "react";
import { useHistory, useParams,useLocation } from "react-router-dom";
import Tooltip from "react-simple-tooltip";
//enable this line when RTE enabled from admin panel
// import Parser from 'html-react-parser';

import { Link } from "react-router-dom";
import { TosterMessageInfo } from "../Toster/Toster";
import "./profile.scss";

import {
  getProfileDetails,
  resumeLink,
  updateProfile,
} from "../../api/ApiRequest";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { globalStrings } from "../../strings";
import { assets } from "../../utils/assets";

const Profile = (props) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const userIdParam = queryParams.get("id");
  const addlinkedin = assets['add-linkedin'];
  const Badge1 = assets['badge-1'];
  const Badge2 = assets['badge-2'];
  const Badge3 = assets['badge-3'];
  const Badge4 = assets['badge-4'];
  const Badge5 = assets['badge-5'];
  const downArrow = assets['downArrow'];
  const editlinkedin = assets['edit-linkedin'];
  const emptyList = assets['empty-challenge-list'];
  const entry = assets['entry'];
  const feedback = assets['feedback'];
  const linkAdd = assets['linkadd'];
  const quote1 = assets['quote1'];
  const quote2 = assets['quote2'];
  const shareresume = assets['resume-share'];
  const upArrow = assets['upArrow'];
  const Edit = assets['edit-pencil'];
  const ProfilePic = assets['profile-pic'];
  const history = useHistory();
  const params = useParams();
  const badgeImages = {
    1: Badge1,
    2: Badge2,
    3: Badge3,
    4: Badge4,
    5: Badge5,
  };
  const state= "past"

  const rankAbbr = {
    1: "st",
    2: "nd",
    3: "rd",
    4: "th",
    5: "th",
  };

  const colorArray = ["#14a0c0", "#9e63c9", "#01cc9b", "#7de330", "#5059ab"];

  const bgColorArray = [
    "rgba(20, 160, 192, 0.5)",
    "rgba(158, 99, 201, 0.5)",
    "rgba(27, 204, 155, 0.5)",
    "rgba(125, 227, 48, 0.5)",
    "rgba(80, 89, 171, 0.5)",
  ];
  const isShared = userIdParam ? true : false;
  const link = ` ${window.location.origin}/shared-profile?id=
    ${localStorage.getItem("PMuserId")}`;

  const [email, setUserEmail] = useState();
  const [userName, setUserName] = useState();
  const [linkedin, setLinkedin] = useState();
  const [showResume, setShowResume] = useState(false);
  const [userJoininig, setUserJoining] = useState();
  const [userImage, setUserImage] = useState();
  const [liveChallengeCount, setLiveChanllageCount] = useState();
  const [enteredChallengeCount, setEnteredChallengeCount] = useState();
  const [wonChallengeCount, setWonChanllageCount] = useState();
  const [challengeData, setChallengeData] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const [challengeWon, setChallengeWon] = useState([]);
  const [topSkill, setTopSkill] = useState([]);
  const [editLinked, setEditLinked] = useState(false);
  const [editUserName, setEditUserName] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(
    !!localStorage.getItem("PMuserId") && !!localStorage.getItem("PMtoken")
  );

  //disable this function when RTE enabled from admin panel
  function Parser(value) {
    return value;
  }
  let a = [{ day: "2-digit" }, { month: "short" }, { year: "numeric" }];

  const setProfileData = (res) => {
    setAllUserData(res.data);
    if (res.data && res.data.linkedin_url) {
      setLinkedin(res.data.linkedin_url);
    }
    let challengeArray = [];
    // let skillArray = []
    let skills = [
      {
        title: "Market Sizing",
        score: 0,
        outOfScore: 0,
        color: "#14a0c0",
        bgColor: "rgba(20, 160, 192, 0.5)",
        desc:
          "How does one guesstimate the potential market i.e. looking at certain parameters to narrow down on multiple target groups for arriving at a accurate market size",
      },
      {
        title: "Creative Problem Solving",
        score: 0,
        outOfScore: 0,
        color: "#9e63c9",
        bgColor: "rgba(158, 99, 201, 0.5)",
        desc:
          "Having a problem first approach and defining the problem in detail with a major focus on user pain points and external market factors",
      },
      {
        title: "Metrics Thinking",
        score: 0,
        outOfScore: 0,
        color: "#01cc9b",
        bgColor: "rgba(27, 204, 155, 0.5)",
        desc:
          "Understanding of KPIs or what metrics need to be tracked for the success and monitoring of your feature suggestions",
      },
      {
        title: "Structure of document",
        score: 0,
        outOfScore: 0,
        color: "#7de330",
        bgColor: "rgba(125, 227, 48, 0.5)",
        desc:
          "The flow of the submitted document should be easily consumable with the first few slides considered as inputs to the final recommendations",
      },
      {
        title: "Go-to-market",
        score: 0,
        outOfScore: 0,
        color: "#5059ab",
        bgColor: "rgba(80, 89, 171, 0.5)",
        desc:
          "Understanding of acquisition channels and distribution strategy is crucial for PMs to plan feature launches and post release execution",
      },
      {
        title: "Tech Understanding",
        score: 0,
        outOfScore: 0,
        color: "#e38629",
        bgColor: "rgba(227, 134, 41, 0.5)",
        desc:
          "This rubric is to test the detailed language and edge cases of specifications written to enable engineers to make their decisions",
      },
      {
        title: "Design Sense",
        score: 0,
        outOfScore: 0,
        color: "#398629",
        bgColor: "rgba(57, 134, 41, 0.5)",
        desc:
          "How does one do well in wireframing / prototyping as the skill of converting ideas on a visual UI is important for PMs to connect with design teams",
      },
    ];
    for (let c = 0; c < res.all_entries.length; c++) {
      let challengeObj = {};
      let ch = res.all_entries[c];
      challengeObj.title = ch.challenge_name;
      challengeObj.subtitle = ch.challenge_detail_text;
      challengeObj.feedback = ch.remark_text;

      let outOfScore = 0;
      let score = 0;
      let evaluation = [];
      for (let j = 0; j < ch.categories_listing.length; j++) {
        outOfScore += ch.categories_listing[j].out_of_score;
        score += ch.rank_details.categories_marks[j].marks;
        let evaluationObj = {};
        evaluationObj.title = ch.categories_listing[j].title;
        evaluationObj.score = ch.rank_details.categories_marks[j].marks;
        evaluationObj.outOfScore = ch.categories_listing[j].out_of_score;
        evaluationObj.color = colorArray[j];
        evaluationObj.bgColor = bgColorArray[j];
        evaluation.push(evaluationObj);

        if (
          ch.categories_listing[j].title.toLowerCase() ==
          "Market Sizing".toLowerCase()
        ) {
          skills[0].score += ch.rank_details.categories_marks[j].marks;
          skills[0].outOfScore += ch.categories_listing[j].out_of_score;
        } else if (
          ch.categories_listing[j].title.toLowerCase() ==
          "Creative Problem Solving".toLowerCase()
        ) {
          skills[1].score += ch.rank_details.categories_marks[j].marks;
          skills[1].outOfScore += ch.categories_listing[j].out_of_score;
        } else if (
          ch.categories_listing[j].title.toLowerCase() ==
          "Metrics Thinking".toLowerCase()
        ) {
          skills[2].score += ch.rank_details.categories_marks[j].marks;
          skills[2].outOfScore += ch.categories_listing[j].out_of_score;
        } else if (
          ch.categories_listing[j].title.toLowerCase() ==
          "Structure of document and writing skills".toLowerCase()
        ) {
          skills[3].score += ch.rank_details.categories_marks[j].marks;
          skills[3].outOfScore += ch.categories_listing[j].out_of_score;
        } else if (
          ch.categories_listing[j].title.toLowerCase() ==
          "Go-to-market".toLowerCase()
        ) {
          skills[4].score += ch.rank_details.categories_marks[j].marks;
          skills[4].outOfScore += ch.categories_listing[j].out_of_score;
        } else if (
          ch.categories_listing[j].title.toLowerCase() ==
          "Tech Understanding".toLowerCase() ||
          ch.categories_listing[j].title.toLowerCase() ==
          "Technical Knowledge".toLowerCase()
        ) {
          skills[5].score += ch.rank_details.categories_marks[j].marks;
          skills[5].outOfScore += ch.categories_listing[j].out_of_score;
        } else if (
          ch.categories_listing[j].title.toLowerCase() ==
          "Design sense and wireframing".toLowerCase()
        ) {
          skills[6].score += ch.rank_details.categories_marks[j].marks;
          skills[6].outOfScore += ch.categories_listing[j].out_of_score;
        }
      }

      challengeObj.outOfScore = outOfScore;

      challengeObj.evaluation = evaluation;
      challengeObj.score = score;
      challengeObj.rank = ch.rank;
      challengeObj.challenge_id = ch.challenge_id;
      challengeObj.entry_id = ch._id;
      challengeObj.drive_url = ch.drive_url ? ch.drive_url : "";
      challengeArray.push(challengeObj);
    }

    for (let s = 0; s < skills.length; s++) {
      if (skills[s].score > 0 && skills[s].outOfScore > 0) {
        skills[s].score = Math.round(
          (skills[s].score / skills[s].outOfScore) * 100
        );
      }
    }
    setTopSkill(skills);

    setChallengeData(challengeArray);

    let userCount = [
      { id: 1, count: 0, badge: Badge1 },
      { id: 2, count: 0, badge: Badge2 },
      { id: 3, count: 0, badge: Badge3 },
      { id: 4, count: 0, badge: Badge4 },
      { id: 5, count: 0, badge: Badge5 },
    ];
    if (res.user_count_rank.length) {
      for (let u = 0; u < userCount.length; u++) {
        if (
          res.user_count_rank[0] &&
          res.user_count_rank[0]._id == userCount[u].id
        ) {
          userCount[u].count = res.user_count_rank[0].count;
        }
        if (
          res.user_count_rank[1] &&
          res.user_count_rank[1]._id == userCount[u].id
        ) {
          userCount[u].count = res.user_count_rank[1].count;
        }
        if (
          res.user_count_rank[2] &&
          res.user_count_rank[2]._id == userCount[u].id
        ) {
          userCount[u].count = res.user_count_rank[2].count;
        }
        if (
          res.user_count_rank[3] &&
          res.user_count_rank[3]._id == userCount[u].id
        ) {
          userCount[u].count = res.user_count_rank[3].count;
        }
        if (
          res.user_count_rank[4] &&
          res.user_count_rank[4]._id == userCount[u].id
        ) {
          userCount[u].count = res.user_count_rank[4].count;
        }
      }
      setChallengeWon(userCount);
    } else {
      setChallengeWon(userCount);
    }
  };

  const getProfile = () => {
    getProfileDetails()
      .then((res) => {
        if (res.code === 200) {
          setProfileData(res);
        }
      })
      .catch((err) => { });
  };

  const setAllUserData = (data) => {
    setUserEmail(data.email);
    setUserName(data.name);
    setUserImage(data.profile_picture);
    let s = join(data.created_timestamp, a, " ");
    setUserJoining(s);
  };

  // const setChallengesData = (data) => {
  // 	setChallengeData([...challengeData, ...data.all_entries]);
  // 	setLiveChanllageCount(data.current_live_challenges_count);
  // 	setEnteredChallengeCount(data.user_entries_count);
  // 	setWonChanllageCount(data.user_winner_count);
  // }

  function join(t, a, s) {
    function format(m) {
      let f = new Intl.DateTimeFormat("en", m);
      return f.format(t);
    }
    return a.map(format).join(s);
  }

  useEffect(() => {
    if (userIdParam) {
      const userId = userIdParam.toString();
      resumeLink({user_id:userId}).then((res) => {
        if (res.code === 200) {
          setProfileData(res);
        }
      });
      
    } else {
      getProfile();
    }
  }, []);

  const handleClickOnChangePassword = () => {
    props.setUserEmail(email);
    props.onModalChange("reset-password", true);
  };

  const logout = () => {
    props.changeLoginState(false);
    localStorage.removeItem("PMuserId");
    localStorage.removeItem("PMtoken");
    history.push("/product-challenges");
  };

  const addDriveUrl = (entryId) => {
    props.setEntryId(entryId);
    props.setLinkUrl("");
    props.onModalChange("uploadDrive", true);
  };
  const updateDrive = (entryId, linkUrl) => {
    props.setEntryId(entryId);
    props.setLinkUrl(linkUrl);
    props.onModalChange("uploadDrive", true);
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleProfilePic = async (e) => {
    let file = e.target?.files[0];

    if (file) {
      let base64 = await toBase64(file);

      let payload = {
        updateType: "profile_pic",
        profile_picture: base64,
      };

      updateProfile(payload)
        .then((res) => {
          if (res.data?.profile_picture) {
            setUserImage(res.data.profile_picture);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const updateLinkedin = () => {
    let payload = {
      updateType: "linkedin_url",
      linkedin_url: linkedin,
    };

    updateProfile(payload)
      .then((res) => {
        setEditLinked(false);
      })
      .catch((err) => {
        setEditLinked(false);
        console.log(err);
      });
  };

  const updateUserName = () => {
    let payload = {
      updateType: "name",
      name: userName,
    };

    updateProfile(payload)
      .then((res) => {
        setEditUserName(false);
      })
      .catch((err) => {
        setEditUserName(false);
        console.log(err);
      });
  };

  return (
    <div className="profile-container">
      <link rel="canonical" href="https://pmschool.io/terms-and-condition" />
      <div className="basic-info">
        <div className="profile-info">
          <div className="imageDiv">
            <img
              className="profile-pic"
              src={userImage ? userImage : ProfilePic}
              alt=""
              loading="lazy"
              onError={(e) => {
                e.target.src = ProfilePic;
              }}
            />

            {!isShared && (
              <label className="edit-icon">
                <img loading="lazy" alt="" src={Edit} />
                <input
                  type="file"
                  style={{ opacity: 0 }}
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={(e) => handleProfilePic(e)}
                />
              </label>
            )}
          </div>

          {/* <div className="btn-wrapper row g-0">
						<div className="bordered-button col-6" onClick={() => { props.changeLoginState(false) }, logout}>LOGOUT</div>
					</div> */}

          <div className="content-wrapper">
            {!isShared ? (
              editUserName ? (
                <div className="nameBox">
                  <input
                    type="text"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    className="name"
                  />
                  <img
                    alt=""
                    src={addlinkedin}
                    loading="lazy"
                    onClick={() => {
                      if (userName) {
                        updateUserName();
                        setEditUserName(false);
                      }
                    }}
                  />
                </div>
              ) : (
                <div className="nameBox">
                  <div className="name">{userName}</div>
                  <img loading="lazy" alt="" src={editlinkedin} onClick={() => setEditUserName(true)} />
                </div>
              )
            ) : (
              <div className="name">{userName}</div>
            )}
            <a href="" className="email">
              {email}
            </a>

            {
              !isShared ? (
                editLinked ? (
                  <div className="linkedinBox">
                    <input
                      type="text"
                      placeholder={"Add your Linkedin profile URL"}
                      value={linkedin}
                      onChange={(e) => setLinkedin(e.target.value)}
                    // onKeyUp={(e) => {
                    //   if (e.keycode == 13 || e.which == 13) {
                    //     updateLinkedin();
                    //   }
                    // }}
                    />
                    &nbsp;&nbsp;
                    <img loading="lazy" alt="" src={addlinkedin} onClick={() => updateLinkedin()} />
                  </div>
                ) : !linkedin ? (
                  <div className="linkedinBox">
                    <div className="linkedIn">Add your Linkedin profile URL</div>
                    &nbsp;&nbsp;
                    <img loading="lazy" alt="" src={addlinkedin} onClick={() => setEditLinked(true)} />
                  </div>
                ) : (
                  <div className="linkedinBox">
                    <div className="linkedIn">{linkedin}</div>
                    &nbsp;&nbsp;
                    <img loading="lazy" alt="" src={editlinkedin} onClick={() => setEditLinked(true)} />
                  </div>
                )
              ) : null
              // (
              //   <div className="linkedinBox">
              //     <div className="linkedIn">
              //       {linkedin || "Add your Linkedin profile URL"}
              //     </div>
              //   </div>
              // )
            }

            {!isShared && window.innerWidth > 767 ? (
              showResume ? (
                <div className="resumeBox">
                  <div className="title">{link}</div>
                  &nbsp;&nbsp;
                  <img
                    loading="lazy"
                    alt=""
                    src={shareresume}
                    onClick={() => {
                      copy(`${link}`);
                      TosterMessageInfo({ msg: globalStrings.link_copied });
                    }}
                  />
                </div>
              ) : (
                <div className="btn-green" onClick={() => setShowResume(true)}>
                  Share As Resume
                </div>
              )
            ) : null}
          </div>
        </div>

        {!isShared && window.innerWidth < 768 ? (
          showResume ? (
            <div className="resumeBox">
              <div className="title">{link}</div>
              &nbsp;&nbsp;
              <img
                loading="lazy"
                alt=""
                src={shareresume}
                onClick={() => {
                  copy(`${link}`);
                  TosterMessageInfo({ msg: globalStrings.link_copied });
                }}
              />
            </div>
          ) : (
            <div className="btn-green" onClick={() => setShowResume(true)}>
              Share As Resume
            </div>
          )
        ) : null}
        <div
          className="challenge-badge"
          style={challengeData.length ? {} : { opacity: 0.4 }}>
          <div className="title">Challenges Won</div>
          <div className="flexDiv">
            {challengeWon
              // .filter((c) => c.count > 0)
              .map((challenge) => (
                <div style={{ position: "relative" }}>
                  <img loading="lazy" src={challenge.badge} alt="" />
                  <div className="hexagon">
                    <span className="badgeCount">{challenge.count}</span>
                  </div>
                </div>
              ))}
          </div>
        </div>

        <div
          className="top-skills"
          style={challengeData.length ? {} : { opacity: 0.4 }}>
          <div className="title">Top Skills</div>
          {topSkill.map((s) => (
            <Tooltip
              content={<div className="tooltipBox">{s.desc}</div>}
              customCss={{ display: "block" }}
              placement={"bottom"}
              radius={5}
              padding={15}
              zIndex={1000}>
              <div style={s.score ? {} : { opacity: "0.25" }}>
                <div className="skill">{s.title}</div>
                <div className="flexDiv">
                  <div className="scoreBar" style={{ backgroundColor: s.bgColor }}>
                    <div
                      className="score"
                      style={{ backgroundColor: s.color, width: `${s.score}%` }}
                    />
                  </div>
                  <div className="scorePer">{s.score}%</div>
                </div>
              </div>
            </Tooltip>
          ))}
        </div>

        {!isShared && (
          <div
            className="btn-green"
            onClick={
              (() => {
                props.changeLoginState(false);
              },
                logout)
            }>
            Logout
          </div>
        )}
      </div>

      <div className="challenge-list">
        <div className="title">My Challenges</div>

        {challengeData.length ? (
          <div className="challenges">
            {challengeData.map((c, index) => {
              return (
                <div className="challenge-box">
                  <div className="detail">
                    <div className="titleBox">
                      <div className="title">{c.title}</div>
                      <div className="subtitle">{c.subtitle}</div>
                    </div>
                    <div className="scoreBox">
                      <div className="header">Score/{c.outOfScore}</div>
                      <div className="score">
                        <CircularProgressbar
                          value={c.score}
                          text={c.score}
                          styles={buildStyles({
                            textColor: "#FFF",
                            pathColor: "#FFF",
                            trailColor: "#424242",
                            textSize: "24px",
                          })}
                        />
                      </div>
                    </div>
                    <div className="breakBox">
                      <div className="header">Score Break Up</div>
                      <div className="breakScoreBox">
                        {c.evaluation.map((e) => (
                          <Tooltip
                            content={
                              <div className="tooltipBox">
                                <div>{e.title}</div>
                                <div>
                                  {e.score}/{e.outOfScore}
                                </div>
                              </div>
                            }
                            customCss={{ width: `${e.score}%` }}
                            placement={"bottom"}
                            radius={5}
                            padding={15}>
                            <div style={{ backgroundColor: e.color }} className="count" />
                          </Tooltip>
                        ))}
                      </div>
                    </div>

                    <div className="solutionBox">
                      <div className="header">Solution</div>
                      {c.rank ? (
                        <div>
                          <div className="flexDiv">
                            <img loading="lazy" alt="" src={badgeImages[c.rank]} className="badgeImg" />
                            <div className="title">
                              {c.rank}
                              {rankAbbr[c.rank]} RANK
                            </div>
                          </div>

                          <div className="flexDiv">
                            <Link
                              className="entry"
                              to={{
                                pathname: `/ChallengeDetails`,
                                search: `?state=${state}&id=${c.challenge_id}`,
                              }}
                              // to={"/ChallengeDetails/past/" + c.challenge_id}
                            >
                              View Entry
                            </Link>
                            <img loading="lazy" alt="" src={entry} className="entryIcon" />
                          </div>
                        </div>
                      ) : c.drive_url && isLoggedIn && !isShared ? (
                        <div className="flexDiv">
                          <div
                            className="entry"
                            onClick={() => updateDrive(c.entry_id, c.drive_url)}
                            style={{ cursor: "pointer" }}>
                            Update Link
                          </div>
                          <img
                            loading="lazy"
                            style={{ cursor: "pointer" }}
                            alt=""
                            src={linkAdd}
                            onClick={() => {
                              copy(`${c.drive_url}`);
                              TosterMessageInfo({
                                msg: globalStrings.link_copied,
                              });
                            }}
                            className="entryIcon"
                          />
                        </div>
                      ) : c.drive_url && !isLoggedIn ? (
                        <div className="flexDiv">
                          <a
                            className="entry"
                            href={c.drive_url}
                            target="_blank"
                            rel="noreferrer">
                            View Link
                          </a>
                          <img
                            loading="lazy"
                            style={{ cursor: "pointer" }}
                            alt=""
                            src={linkAdd}
                            onClick={() => {
                              copy(`${c.drive_url}`);
                              TosterMessageInfo({
                                msg: globalStrings.link_copied,
                              });
                            }}
                            className="entryIcon"
                          />
                        </div>
                      ) : !isShared ? (
                        <div className="flexDiv">
                          <div
                            className="entry"
                            onClick={() => addDriveUrl(c.entry_id)}
                            style={{ cursor: "pointer" }}>
                            Add Link
                          </div>
                          <img loading="lazy" alt="" src={linkAdd} className="entryIcon" />
                        </div>
                      ) : (
                        <div style={{ color: "white" }}>—</div>
                      )}
                    </div>

                    {!isShared && (
                      <div className="arrowBox">
                        <img
                          loading="lazy"
                          src={openIndex === index ? upArrow : downArrow}
                          alt=""
                          onClick={() =>
                            setOpenIndex((prev) => (prev === index ? null : index))
                          }
                        />
                      </div>
                    )}
                  </div>

                  {openIndex === index && (
                    <div className="expandedView">
                      <div className="criteriaBox">
                        <div className="title">Evaluation criteria</div>
                        {c.evaluation.map((e) => (
                          <div className="expanded-evalBox">
                            <div className="scoreBar" style={{ backgroundColor: e.bgColor }}>
                              <div
                                className="score"
                                style={{
                                  backgroundColor: e.color,
                                  width: `${e.score * 5}%`,
                                }}
                              />
                            </div>
                            <div className="evalTitle">{e.title}</div>
                            <div className="evalScore">
                              {e.score}/{e.outOfScore}
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className="feedbackForm">
                        <div className="feedbackDiv">
                          <img loading="lazy" src={feedback} alt="" className="feedIcon" />
                          <div className="feedTitle">Feedback from PM School Experts</div>
                        </div>

                        <div className="feedback">
                          <img loading="lazy" alt="" src={quote1} />
                          <span>{c.feedback}</span>
                          <img loading="lazy" alt="" src={quote2} />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        ) : (
          <div className="challenges">
            <div className="challenge-box" style={{ textAlign: "center" }}>
              <img loading="lazy" src={emptyList} alt="" />
              <div className="emptyTitle">Participate in your first challenge now</div>
              <div className="emptySubtitle">
                Showcase your product skills and build your PM profile for the world to
                see! Share it with recruiters and job openings with one click.
              </div>
              <div className="btnBox">
                <Link to={"/product-challenges"} className="btn-green">
                  Participate Now
                </Link>
                {/* <div className='btn-green'>Participate Now</div> */}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>

  );
};

export default Profile;

import React from 'react'
import MetaTags from 'react-meta-tags'


const MetaTagsScreen = (props) => {
    return (
        <MetaTags>
            <title>{props.title}</title>
            <meta name="description" content={props.desc} />
            <meta property="og:title" content={props.title} />
            <meta property="og:description" content={props.desc} />
            <meta property="og:url" content={'https://pmschool.io'} />
            <meta property="og:image:secure_url" itemprop="image" content={'https://prod2-pmschool.blr1.cdn.digitaloceanspaces.com/assets/images/pmschool-logo.png'} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="PM School" />
        </MetaTags>
    )
}

export default MetaTagsScreen
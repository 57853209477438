import React from "react";
import "./check.scss";
import { useHistory } from "react-router-dom";

const Check = ({
  isCorrect,
  nextQuestionBtn,
  currentQuestion,
  questionData,
  setShowPopup,
  lastQuestionCorrect,
  dayComplete,
  setChallengeComplete,
  challengeComplete,
  correctAns,
  userId,
  pmRunProgramTypeId
}) => {

  const history = useHistory();

  const handleViewProgressClick = () => {    

    const url = `/history?userId=${userId}&pmRunProgramTypeId=${pmRunProgramTypeId}`;
    history.push(url);

  };
  
  return (
    <div
      className={`modal__isCorrect ${
        !dayComplete
          ? !isCorrect && "modal__isCorrect--false"
          : !lastQuestionCorrect && "modal__isCorrect--false"
      }`}
    >
      <div className="modal__left">
        <h3>
          {!dayComplete
            ? isCorrect
              ? "Your answer was correct!"
              : "Your answer was incorrect!"
            : lastQuestionCorrect
            ? "Your answer was correct!"
            : "Your answer was incorrect!"}
        </h3>
        {!dayComplete ? (
          isCorrect ? (
            <p>
              Congratulations on embarking on your PM Run! Wishing you the best
              as you tackle the upcoming questions with fervor and triumph.
              <br />
              Good luck on your extraordinary journey!
            </p>
          ) : (
            <p>
              No need to worry! Congratulations on embarking on your PM Run!
              Embrace challenges, conquer obstacles, and believe in your
              success.
              <br />
              Best of luck on your journey!
            </p>
          )
        ) : lastQuestionCorrect ? (
          <p>
            Congratulations on embarking on your PM Run! Wishing you the best as
            you tackle the upcoming questions with fervor and triumph.
            <br />
            Good luck on your extraordinary journey!
          </p>
        ) : (
          <p>
            No need to worry! Congratulations on embarking on your PM Run!
            Embrace challenges, conquer obstacles, and believe in your success.
            <br />
            Best of luck on your journey!
          </p>
        )}
        {!dayComplete
          ? !isCorrect && (
              <h3>
                <span>Correct answer-</span> ‘{correctAns}’
              </h3>
            )
          : !lastQuestionCorrect && (
              <h3>
                <span>Correct answer-</span> ‘{questionData.oldAnswers}’
              </h3>
            )}
        <a href="/product-management-course" target="_blank">
          Kickstart your PM career, apply for the next cohort
        </a>
      </div>
      <div className="divider"></div>
      {questionData.currentDay * 4 === currentQuestion.questionNo ||
      dayComplete ? (
        <div className="modal__right">
          <h3>Day {questionData.daysCompleted} completed!</h3>
          <p>Come back tomorrow and keep winning!</p>
          <div className="Button_container">
            <button
              className="nextQ__btn"
              onClick={() =>
                questionData.totalDays === questionData.currentDay &&
                questionData.currentDay * 4 === currentQuestion.questionNo
                  ? setChallengeComplete(true)
                  : setShowPopup(true)
              }
            >
              SHARE ACHIEVEMENT
            </button>
            <button
              className="viewProgress__btn"
              onClick={handleViewProgressClick}
            >
              VIEW PROGRESS
            </button>
          </div>
        </div>
      ) : (
        <div className="modal__right">
          <button className="nextQ__btn" onClick={nextQuestionBtn}>
            GO TO NEXT QUESTION
          </button>
        </div>
      )}
    </div>
  );
};

export default Check;

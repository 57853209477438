import React from "react";
import "./styles/font-awesome.min.css";
import "./styles/slick.min.css";
import "./styles/style.css";
import "./styles/responsive.css";

import Banner from "./banner";
import Classes from "./classes";
import Learning from "./learning";
import Weeks from "./week";
import Mentor from "../homepage/for-mentor-section";
import FAQ from "./faq";
import MetaTagsScreen from "../../utils/metaTags";
import Companies from "./companies";

const course = () => {
  return (
    <div>
      <MetaTagsScreen
        title="Best Product Management Online Courses in India | PM School"
        desc="Enroll for the PM School's product management online courses and get interactive live classes, mock interviews, video content. Kickstart your product manager career now."
      />
      <link
        rel="canonical"
        href="https://pmschool.io/product-management-course"
      />
      <Banner />
      <Companies />
      <Classes />
      <Learning />
      <Weeks />

      <Mentor />
      <FAQ />
    </div>
  );
};

export default course;

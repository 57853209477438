import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { osName, osVersion } from "react-device-detect";
import { useGoogleLogin } from "react-google-login";
import { LinkedIn } from "react-linkedin-login-oauth2";
import { useHistory } from "react-router-dom";

import {
 loginRequest,
 signupRequest,
 socialLogin,
} from "../../../api/ApiRequest";
import { globalStrings } from "../../../strings";
import { TosterMessageError, TosterMessageSuccess } from "../../Toster/Toster";
import "./login.scss";
import { assets } from "../../../utils/assets";

const NameField = (props) => (
 <div key={`div-${props.randomKey}`} className="field-wrapper">
  <input
   className="input-field"
   type="text"
   placeholder="Full Name"
   onChange={props.handleName}
   value={props.name}
   key={props.randomKey}
  />
 </div>
);

const EmailField = (props) => (
 <div key={`div-${props.randomKey}`} className="field-wrapper">
  <input
   className="input-field"
   type="email"
   placeholder="Email ID"
   onChange={props.handleEmail}
   value={props.email}
   key={props.randomKey}
  />
 </div>
);

function LoginModal(props) {
  const googleLogo=assets['google-logo-png'];
  const linkedInLogo=assets['linkedin-png'];
 const [isShow, setIsShow] = useState(false);
 const [name, setName] = useState();
 const [password, setPassword] = useState();
 const [email, setEmail] = useState();
 const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
 const history = useHistory();

 const handleName = (e) => {
  setName(e.target.value);
 };

 const handleEmail = (e) => {
  setEmail(e.target.value);
 };

 const handlePassword = (e) => {
  setPassword(e.target.value);
 };

 const resetFields = () => {
  setName("");
  setPassword("");
  setEmail("");
 };

 const register = () => {
  let emailId = email;

  if (!emailRegex.test(emailId)) {
   TosterMessageError({ msg: globalStrings.invalid_email });
  } else {
   signupRequest(email, password, name, "USER")
    .then((res) => {
     if (res.code === 200) {
      localStorage.setItem("PMuserId", res["data"]["_id"]);
      localStorage.setItem("PMtoken", res["data"]["token"]);
      props.onChangeView("", false);
      props.changeLoginState(true);
      TosterMessageSuccess({ msg: globalStrings.signup_success });
      resetFields();
     } else {
      //
      TosterMessageError({ msg: globalStrings.something_went_wrong });
     }
    })
    .catch((err) => {
     //
     if (err == "EMAIL_ALREADY_EXISTS") {
      TosterMessageError({ msg: globalStrings.email_exists });
     } else {
      TosterMessageError({ msg: globalStrings.something_went_wrong });
     }
    });
  }
 };

 const login = () => {
  let emailId = email;

  if (!emailRegex.test(emailId)) {
   TosterMessageError({ msg: globalStrings.invalid_email });
  } else {
   loginRequest(email, password, osName, osVersion, "ABC123", "device_name")
    .then((res) => {
     if (res.code === 200) {
      localStorage.setItem("PMuserId", res["data"]["_id"]);
      localStorage.setItem("PMtoken", res["data"]["token"]);
      props.onChangeView("", false);
      TosterMessageSuccess({ msg: globalStrings.signin_success });
      props.changeLoginState(true);
      if (props.redirectUrl) {
       history.push(props.redirectUrl);
      }
     } else {
      //
      TosterMessageError({ msg: globalStrings.something_went_wrong });
     }
    })
    .catch((err) => {
     let msg;
     //
     if (err == "INVALID_CREDENTIALS") {
      msg = globalStrings.wrong_cred;
     } else if (err == "NOT_FOUND") {
      msg = globalStrings.user_not_found;
     } else {
      msg = globalStrings.something_went_wrong;
     }
     TosterMessageError({ msg: msg });
    });
  }
 };

 const socialSigninReq = (data) => {
  socialLogin(data)
   .then((res) => {
    if (res.code === 200) {
     //
     localStorage.setItem("PMuserId", res["data"]["_id"]);
     localStorage.setItem("PMtoken", res["data"]["token"]);
     props.onChangeView("", false);
     props.changeLoginState(true);
     TosterMessageSuccess({ msg: globalStrings.signin_success });
    } else {
     //
     props.onChangeView("", false);
     TosterMessageError({ msg: globalStrings.something_went_wrong });
    }
   })
   .catch((err) => {
    props.onChangeView("", false);
    TosterMessageError({ msg: globalStrings.something_went_wrong });
   });
 };

 const responseGoogle = (response) => {
  const profile = response.getBasicProfile();
  const id_token = response.getAuthResponse().id_token;
  const data = {
   provider_token: id_token,
   provider: "google",
   provider_id: profile.getId(),
   profile_image: profile.getImageUrl(),
   name: profile.getName(),
   email: profile.getEmail(),
   role: "USER",
  };
  socialSigninReq(data);
 };

 const responseLinkedin = async (response) => {
  if (response.code) {
   const data = {
    provider_token: response.code,
    provider: "linkedin",
    provider_id: "",
    profile_image: "",
    name: "",
    email: "",
    role: "USER",
   };

   socialSigninReq(data);
   // let token = await getAccessToken(response.code)

   // if (token) {
   //   let profile = await getLinkedInProfile(token.access_token)
   //   let email = await getLinkedInEmail(token.access_token)

   //   if (email && email.elements) {
   //     const data = {
   //       provider_token: response.code,
   //       provider: 'linkedin',
   //       provider_id: profile.id,
   //       profile_image: '',
   //       name: profile.localizedFirstName + " " + profile.localizedLastName,
   //       email: email.elements[0]['handle~']['emailAddress'],
   //       role: 'USER'
   //     }

   //     socialSigninReq(data);
   //   } else {
   //     props.onChangeView("", false);
   //     TosterMessageError({ msg: globalStrings.something_went_wrong });
   //   }
   // } else {
   //   props.onChangeView("", false);
   //   TosterMessageError({ msg: globalStrings.something_went_wrong });
   // }
  }
 };

 const queryToObject = (queryString) => {
  const pairsString =
   queryString[0] === "?" ? queryString.slice(1) : queryString;
  const pairs = pairsString
   .split("&")
   .map((str) => str.split("=").map(decodeURIComponent));
  return pairs.reduce(
   (acc, [key, value]) => (key ? { ...acc, [key]: value } : acc),
   {}
  );
 };

 const failGoogle = (response) => {};

 const failLinkedin = (response) => {};

 const { signIn, loaded } = useGoogleLogin({
  onSuccess: responseGoogle,
  clientId:
   "776807173178-gqjn0tp1i8p5rfv08b6g3bu25nekt6r8.apps.googleusercontent.com",
  cookiePolicy: "single_host_origin",
  onFailure: failGoogle,
 });

 return (
  <div className="tabs-container">
   <Tabs
    id="controlled-tab-example"
    activeKey={props.tabKey}
    onSelect={(k) => props.setTabKey(k)}>
    <Tab eventKey="login" title="Login">
     <div className="content-body">
      <EmailField
       randomKey={"login-email"}
       email={email}
       handleEmail={handleEmail}
      />
      <div className="field-wrapper d-flex justify-content-between">
       <input
        className="input-field"
        type={isShow ? "text" : "password"}
        placeholder="Password"
        onChange={handlePassword}
        value={password}
       />
       <button
        onClick={() => {
         setIsShow(!isShow);
        }}
        className="visible-icon"></button>
      </div>
      <div className="forget-password-wrapper d-flex justify-content-between">
       <div className="chk-wrapper">
        <input type="checkbox" id="chkremember"></input>
        <label className="lblremember" htmlFor="chkremember">
         Remember me
        </label>
       </div>
       <div>
        <button
         className="forgetlink btn btn-link"
         onClick={() => {
          props.onChangeView("forgot-password", true);
         }}>
         Forgot Password?
        </button>
       </div>
      </div>
      <div className="btnWrapper">
       {/* <Link className="btn-green" to="/ChallengesDetails/live">
                    Login
                  </Link> */}
       <div className="btn-green" onClick={login}>
        Login
       </div>
      </div>
     </div>
     <div className="content-footer">
      <div className="title">
       <span className="login-with-header">or Login through</span>
      </div>
      <div className="login-with-wrapper d-flex justify-content-center">
       <img
        loading="lazy"
        onClick={signIn}
        className="login-with-icon"
        alt=""
        src={googleLogo}
       />
       <LinkedIn
        clientId="77fh42hvpv7bo3"
        redirectUri="https://pmschool.io/linkedin"
        scope="r_emailaddress,r_liteprofile"
        onFailure={failLinkedin}
        onSuccess={responseLinkedin}
        redirectPath="/linkedin">
        <img loading="lazy" className="login-with-icon" alt="" src={linkedInLogo} />
       </LinkedIn>
      </div>
     </div>
    </Tab>
    <Tab eventKey="signup" title="Sign Up">
     <div className="content-body-signup">
      <NameField
       randomKey={"signup-name"}
       name={name}
       handleName={handleName}
      />
      <EmailField
       randomKey={"login-email"}
       email={email}
       handleEmail={handleEmail}
      />
      <div className="field-wrapper d-flex justify-content-between">
       <input
        className="input-field"
        type={isShow ? "text" : "password"}
        placeholder="Password"
        onChange={handlePassword}
        value={password}
       />
       <button
        onClick={() => {
         setIsShow(!isShow);
        }}
        className="visible-icon"></button>
      </div>
      <div className="btnWrapper">
       {/* <Link className="btn-green" to="/ChallengesDetails/live">
                    Join PM SCHOOL
                  </Link> */}
       <div className="btn-green" onClick={register}>
        Join PM SCHOOL
       </div>
      </div>
      <span className="caption-note">
       By submitting this form you agree to our terms and conditions
      </span>
     </div>
     <div className="content-footer">
      <div className="title">
       <span className="login-with-header">or Sign up through</span>
      </div>
      <div className="login-with-wrapper d-flex justify-content-center">
       <img
        onClick={signIn}
        className="login-with-icon"
        alt=""
        src={googleLogo}
        loading="lazy"
       />
       <LinkedIn
        clientId="77fh42hvpv7bo3"
        redirectUri="https://pmschool.io/linkedin"
        scope="r_emailaddress,r_liteprofile"
        onFailure={failLinkedin}
        onSuccess={responseLinkedin}
        redirectPath="/linkedin">
        <img loading="lazy" className="login-with-icon" alt="" src={linkedInLogo} />
       </LinkedIn>
      </div>
     </div>
    </Tab>
   </Tabs>
  </div>
 );
}
export default LoginModal;

import React from "react";
import "./styles/_homepage.scss";

import MetaTagsScreen from "../../utils/metaTags";
import LogoContainer from "./LogoContainer";
import Banner from "./banner";
import MentorsSection from "./for-mentor-section";
import Mentors from "./industries-mentors";
import Challenge from "./our-challenges";
import PmRunBox from "./pmRunBox";
import SlickCarousal from "./slick-carousel-wrapper";
import VideoContainer from "./video-container";
import WhyPmSchool from "./why-pm-school";

const HomePage = () => {
 return (
  <div className="homepage-main">
   <MetaTagsScreen
    title="Product Management Certification Program in India | PM School"
    desc="India's leading product management certification and training program with job assistance. PM School offers cohort-based program by experienced mentors. Apply today."
   />
   <link rel="canonical" href="https://pmschool.io/" />
   <Banner />
   <PmRunBox />
   <LogoContainer />
   <WhyPmSchool />
   {/* <Companies /> */}

   <Mentors />
   <SlickCarousal />
   <Challenge />
   <MentorsSection />
   <VideoContainer />
  </div>
 );
};

export default HomePage;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  getProfileDetails,
  getUserQuestionData,
  submitQuestion,
  getCouponCode
} from "../../api/ApiRequest";
import PmRunAchieve from "../pm-run-achieve";
import Question from "./Question";
import "./index.scss";
import ModalComponent from "../comon-component/modal/Modal";

const PmRunQna = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [challengeComplete, setChallengeComplete] = useState(false);
  const [profileData, setProfileData] = useState([]);
  const [questionData, setQuestionData] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState([]);
  const [totalDays, setTotalDays] = useState([]);
  const [isAnswerCorrect, setIsAnswerCorrect] = useState(null);
  const [correctAns, setCorrectAns] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [selectedAnswers, setSelectedAnswers] = useState([]);
  const [coupon, setCoupon] = useState("");
  const [showCoupn, setShowCoupon] = useState(false);
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const location = useLocation();
  const [programId, setProgramId] = useState("");

  const questionSubmit = (e) => {
    e.preventDefault();
    submitQuestion({
      userId: profileData.id,
      questionId: currentQuestion?._id,
      answerIds: selectedAnswers,
      pmRunProgramTypeId: programId,
    })
      .then((res) => res)
      .then((json) => {
        setIsSubmitted(true);
        setIsAnswerCorrect(json.isAnswerCorrect);
        setCorrectAns(json.answersData);
        if(json.isLastQuestion&&json.isLastQuestion===1){
          setIsLastQuestion(true);
        }
      })
      .catch((error) => error);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const programId = urlParams.get("programId");
    setProgramId(programId);

    getProfileDetails()
      .then((res) => res)
      .then((json) => {
        setProfileData(json.data);
        getUserQuestionData({
          userId: json.data?.id.toString(),
          pmRunProgramTypeId: programId.toString(),
        })
          .then((res) => res)
          .then((json) => {
            setQuestionData(json.data);
            setCurrentQuestion(json.data?.currentQuestion[0]);
          })
          .catch((error) => error);
      })
      .catch((error) => error);
  }, [isLastQuestion]);
  useEffect(() => {
    setTotalDays(
      Array.from({ length: questionData?.totalDays }, (_, i) => i + 1)
    );
  }, []);
  
  useEffect(() => {
    const fetchData = async () => {
      if (programId) {
        const couponResponse = await getCouponCode({
          pmRunProgramTypeId: programId,
        });
        const couponResponseData = couponResponse?.data[0]?.coupon_code;
        if (
          questionData?.daysCompleted === questionData?.totalDays &&
          questionData?.totalDays !== undefined &&
          questionData?.daysCompleted
        ) {
          setShowCoupon(true);
          setCoupon(couponResponseData);
        }
      }
    };
  
    if (programId) {
      fetchData();
    }
  }, [programId, questionData]);
  

  return (
    <>
      {challengeComplete ? (
        <PmRunAchieve userName={profileData.name} />
      ) : (
        <div className="pmRun__qna">
          {showPopup && (
            <ModalComponent
              userId={profileData.id}
              programId={programId}
              day={questionData.daysCompleted}
              userName={profileData.name}
              open={showPopup}
              setShowPopup={setShowPopup}
              questionData={questionData}
              showCoupn={showCoupn}
              coupon={coupon}
            />
          )}

          <h1 className="title">
            Topic for today:{" "}
            <span>{currentQuestion?.categoryId?.category}</span>
          </h1>
          <div className="days__tab">
            {totalDays?.map((day, idx) => (
              <div
                key={idx}
                className={`day ${
                  day === questionData.currentDay && "day--active"
                } ${day <= questionData.daysCompleted && "day--complete"}`}
              >
                <div className="day__circle">
                  <div className="circle__wrapper">
                    <div className="circle"></div>
                    <span>Day {day}</span>
                  </div>
                  <div className="line"></div>
                </div>
              </div>
            ))}
          </div>
          <Question
            setShowPopup={setShowPopup}
            question={currentQuestion?.question}
            questionNo={currentQuestion?.questionNo}
            answers={currentQuestion?.answers}
            multiSelection={currentQuestion?.isMultiSelectOn}
            programId={programId}
            questionId={currentQuestion?._id}
            userId={profileData.id}
            setQuestionData={setQuestionData}
            setCurrentQuestion={setCurrentQuestion}
            currentQuestion={currentQuestion}
            questionData={questionData}
            dayComplete={questionData.isDayComplete}
            lastQuestionCorrect={questionData.isLastQuestionCorrect}
            setChallengeComplete={setChallengeComplete}
            challengeComplete={challengeComplete}
            isAnswerCorrect={isAnswerCorrect}
            setIsAnswerCorrect={setIsAnswerCorrect}
            correctAns={correctAns}
            setCorrectAns={setCorrectAns}
            isSubmitted={isSubmitted}
            setIsSubmitted={setIsSubmitted}
            questionSubmit={questionSubmit}
            selectedAnswers={selectedAnswers}
            setSelectedAnswers={setSelectedAnswers}
          />
        </div>
      )}
    </>
  );
};

export default PmRunQna;

export const globalStrings = {
    signin_success: "Signed in Successfully!",
    user_not_found: "User Not Found!",
    wrong_cred: "Email id or Password is Wrong!",
    signup_success: "Sign up Successfully!",
    something_went_wrong: "Something Went Wrong!",
    forget_pass_success: "New Password send to Email Successfully!",
    change_pass_success: "Password changed Successfully!",
    change_pass_fail: "Old Password Not Mached!",
    email_exists: "Email Already Exists!",
    invalid_email: "Email is invalid!",
    link_copied: "Link is copied!"
}